import { createSlice } from '@reduxjs/toolkit';

export const popupSlice = createSlice({
  name: 'popup',
  initialState: {
    activePopup: null,
    data: null,
    error: null,
  },
  reducers: {
    openPopup: (state, action) => {
      const { payload } = action;
      state.activePopup = payload.name;
      state.data = payload.data;
      state.error = null;
    },
    closePopup: (state, action) => {
      const { payload } = action;
      state.activePopup = null;
      state.data = null;
      state.error = null;
    },
    errorPopup: (state, action) => {
      const { payload } = action;
      state.error = { ...payload.error };
    },
  },
});
