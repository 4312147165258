import { createSlice } from '@reduxjs/toolkit';

export const toastSlice = createSlice({
  name: 'toast',
  initialState: {
    open: false,
    message: null,
  },
  reducers: {
    toastStart: (state, action) => {
      const { payload } = action;
      state.open = true;
      state.open = payload?.message;
    },
    removeToast: (state) => {
      state.open = false;
      state.message = null;
    },
  },
});
