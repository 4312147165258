import { createSlice } from '@reduxjs/toolkit';

export const openWorkshopSlice = createSlice({
  name: 'openWorkshop',
  initialState: {},
  reducers: {
    setOpenWorkshop: (state, action) => {
      const { payload } = action;
      for (const k in payload.data || {}) {
        state[k] = payload.data[k];
      }
    },
  },
});
