import { createSlice } from '@reduxjs/toolkit';

export const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    loading: false,
    queue: 0,
    error: null,
    message: null,
  },
  reducers: {
    loaderStart: (state, action) => {
      const { payload } = action;
      state.loading = true;
      state.error = null;
      state.message = null;
      state.queue = state.queue + 1;
    },
    removeLoader: (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.error = { ...payload?.error };
      state.message = payload?.message;
    },
  },
});
