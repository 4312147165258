import { getMetabaseToken, sendAnalytics } from '../client/OneistoxApiClient';

export const pageUnitView = async (cohortId, page, meta, payload) => {
  if (cohortId) {
    await sendAnalytics(cohortId, 'PAGE_VIEW', page, meta, payload);
  }
};

export const courseUnitViewV2 = async (cohortId: string, unitId: string) => {
  await sendAnalytics(
    cohortId,
    'LMS_COURSE_UNIT_CLICKS',
    'COURSE_UNIT',
    {},
    { unitId },
  );
};

export const getMetabaseQuestionURL = async (
  productId,
  question: number,
  params = {},
) => {
  const res = await getMetabaseToken(productId, {
    resource: { question },
    params,
    exp: Math.round(Date.now() / 1000) + 10 * 60,
  });

  return 'https://metabase.novatr.com/embed/question/' + res.data;
};

export const getMetabaseDashbooardURL = async (
  productId,
  dashboard: number,
  params = {},
) => {
  const res = await getMetabaseToken(productId, {
    resource: { dashboard },
    params,
    exp: Math.round(Date.now() / 1000) + 10 * 60,
  });

  return 'https://metabase.novatr.com/embed/dashboard/' + res.data;
};

export const tracksignup = (userId, userProp, eventName, eventProp) => {
  if (window?.posthog) {
    window.posthog.identify(userId);
    window.posthog.people.set(userProp);
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.identify(userId);
    window.heap.addUserProperties(userProp);
    window.heap.track(eventName, eventProp);
  }
  if (window?.analytics) {
    window.analytics.identify(userId, userProp);
    window.analytics.track(eventName, eventProp);
  }
};

export const tracklogin = (userId, userProp, eventName, eventProp) => {
  if (window?.posthog) {
    window.posthog.identify(userId);
    window.posthog.people.set(userProp);
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.analytics) {
    window.analytics.identify(userId, userProp);
    window.analytics.track(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.identify(userId);
    window.heap.addUserProperties(userProp);
    window.heap.track(eventName, eventProp);
  }
};

export const onRouteChange = (url, path, referrer, search) => {
  if (window?.analytics) {
    window.analytics?.page(url, {
      url: window.location.href,
      path: path,
      referrer: referrer,
      search: search,
    });
  }
};

export const trackauth = (userId, userProp, eventName, eventProp) => {
  if (window?.posthog) {
    window.posthog.identify(userId);
    window.posthog.people.set(userProp);
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.analytics) {
    window.analytics.identify(userId, { ...userProp, type: 'revalidated' });
    window.analytics.track(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.identify(userId);
    window.heap.addUserProperties(userProp);
    window.heap.track(eventName, eventProp);
  }
};

export const trackproductview = (eventName, eventProp) => {
  if (window?.analytics) {
    window.analytics.track(eventName, eventProp);
  }
  if (window?.posthog) {
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.track(eventName, eventProp);
  }
};

export const trackproductclick = (eventName, eventProp) => {
  if (window?.analytics) {
    window.analytics.track(eventName, eventProp);
  }
  if (window?.posthog) {
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.track(eventName, eventProp);
  }
};

export const trackcourseapp = (eventName, eventProp) => {
  if (window?.analytics) {
    window.analytics.track(eventName, eventProp);
  }
  if (window?.posthog) {
    window.posthog.people.set({
      email: eventProp.email,
    });
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.addUserProperties({
      email: eventProp.email,
    });
    window.heap.track(eventName, eventProp);
  }
};

export const trackebooklead = (eventName, eventProp) => {
  if (window?.analytics) {
    window.analytics.track(eventName, eventProp);
  }
  if (window?.posthog) {
    window.posthog.people.set({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.addUserProperties({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.heap.track(eventName, eventProp);
  }
};

export const trackcontactus = (eventName, eventProp) => {
  if (window?.analytics) {
    window.analytics.track(eventName, eventProp);
  }
  if (window?.posthog) {
    window.posthog.people.set({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.addUserProperties({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.heap.track(eventName, eventProp);
  }
};

export const trackleadform = (eventName, eventProp) => {
  if (window?.analytics) {
    window.analytics.track(eventName, eventProp);
  }
  if (window?.posthog) {
    window.posthog.people.set({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.addUserProperties({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.heap.track(eventName, eventProp);
  }
  if (window?.gtag) {
    eventProp?.product?.name === 'Building Information Modelling' &&
      window.gtag('event', 'conversion', {
        send_to: 'AW-698995117/1pPrCIjvnLoBEK2jp80C',
      });
  }
  if (window?.lintrk) {
    eventProp?.product?.name === 'Building Information Modelling' &&
      window.lintrk('track', { conversion_id: 4658178 });
  }
};

export const trackaddtocart = (eventName, eventProp) => {
  if (window?.analytics) {
    window.analytics?.track(eventName, eventProp);
  }
  if (window?.posthog) {
    window.posthog.people.set({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.addUserProperties({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.heap.track(eventName, eventProp);
  }
  if (window?.gtag) {
    if (eventProp?.product?.product_id?.startsWith('BIM')) {
      window?.gtag('event', 'conversion', {
        send_to: 'AW-698995117/GXHJCPbs4aoDEK2jp80C',
      });
    }
  }
};

export const trackproductpurchased = (eventName, eventProp) => {
  if (window?.analytics) {
    window.analytics?.track(eventName, eventProp);
  }
  if (window?.posthog) {
    window.posthog.people.set({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.addUserProperties({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.heap.track(eventName, eventProp);
  }
  if (window?.gtag) {
    eventProp?.products[0]?.startsWith('BIM') &&
      window.gtag('event', 'conversion', {
        send_to: 'AW-698995117/Y9kuCLTp9tEBEK2jp80C',
        transaction_id: eventProp.transaction_id,
      });
  }
  if (window?.lintrk) {
    eventProp?.products[0]?.startsWith('BIM') &&
      window.lintrk('track', { conversion_id: 6383593 });
  }
};
