import { createSlice } from '@reduxjs/toolkit';

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: { notifications: [] },
  reducers: {
    updateNotification: (state, action) => {
      const { payload } = action;
      state.notifications = payload.data;
    },
  },
});
