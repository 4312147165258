import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: '"Public Sans", sans-serif',

    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },

    subtitle1: {
      fontWeight: 400,
    },
    subtitle2: {
      fontWeight: 400,
    },

    body1: {
      fontWeight: 300,
    },
    body2: {
      fontWeight: 200,
    },
    caption: {
      fontWeight: 200,
    },
    overline: {
      fontWeight: 300,
    },
  },

  palette: {
    primary: {
      main: '#7F56D9',
    },
    secondary: {
      main: '#7F56D9',
    },
    // @ts-ignore
    tertiary: {
      black: '#000',
      white: '#fff',
      lightgrey2: '#f0f5fc',
      lightGold: '#faeb50',
      purplishBlue: '#602afa',
      pale: '#fdf9d3',
      lightPurple: '#faf8ff',
    },
    lightgrey: '#fbfbfb',
    dark: { main: '#111' },
  },
  props: {
    MuiButton: {
      color: 'primary',
      variant: '',
    },
  },
});
theme = responsiveFontSizes(theme);
export default theme;
