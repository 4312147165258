import { createSlice } from '@reduxjs/toolkit';

export const deviceSlice = createSlice({
  name: 'device',
  initialState: {
    width: 0,
    title: '',
    loaded: false,
    country: '',
    currency: 'USD',
  },
  reducers: {
    setScreenWidth: (state, action) => {
      const { payload } = action;

      state.width = payload.width;
    },
    setScreenData: (state, action) => {
      const { payload } = action;

      state.country = payload.country;
      state.currency = payload.currency;
      state.loaded = true;
    },
  },
});
