import axios from 'axios';
import Cookies from 'js-cookie';

const OneistoxAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_PROXY_2,
});
OneistoxAPI.defaults.headers.common['Content-Type'] = 'application/json';
OneistoxAPI.defaults.headers.common['x-auth-token'] = Cookies.get('token');

if (process.env.NEXT_PUBLIC_ENABLE_OVERRIDE_USER) {
  OneistoxAPI.defaults.headers.common['x-auth-override'] =
    process.env.NEXT_PUBLIC_ENABLE_OVERRIDE_USER;
}

export const createHubspotLead = async (data, emailId: string) => {
  return await OneistoxAPI.post(`v1/client/hubspot/lead`, { data, emailId });
};

export const sendgridLeadEBook = async (data) => {
  return await OneistoxAPI.post(`v1/client/sendgrid/lead/ebook`, { data });
};

export const sendgridLeadCourse = async (data) => {
  return await await OneistoxAPI.post(`v1/client/sendgrid/lead/course`, {
    data,
  });
};

export const supportMail = async (data) => {
  return await OneistoxAPI.post(`v1/client/email/contact-us`, { data });
};

export const setAuthToken = (token: string) => {
  Cookies.set('token', token, { expires: 3 });
  Cookies.set('loggenIn', true, { expires: 3 });
  OneistoxAPI.defaults.headers.common['x-auth-token'] = Cookies.get('token');
};

export const removeAuthToken = () => {
  OneistoxAPI.defaults.headers.common['x-auth-token'] &&
    logout().catch((e) => console.error(e));
  Cookies.remove('token');
  Cookies.remove('loggenIn');
  if (window?.posthog) {
    window.posthog.reset();
  }
  OneistoxAPI.defaults.headers.common['x-auth-token'] = null;
};

export const logout = async (): Promise<void> => {
  return await OneistoxAPI.post(`v1/client/user/logout`);
};

export const sendAnalytics = async (
  productId: string,
  event: string,
  subEvent: string,
  meta: any,
  payload: any,
) => {
  if (meta) {
    meta.eventTimestamp = new Date();
  } else {
    meta = { eventTimestamp: new Date() };
  }
  return await OneistoxAPI.post(
    `v1/client/analytics/event/lms/${event}`,
    { subEvent, meta, payload },
    {
      headers: { 'x-product-id': productId },
    },
  );
};

export const emailSignUp = async (emailId: string, password: string) => {
  return await OneistoxAPI.post(`v1/client/signup/email`, {
    emailId,
    password,
  });
};

export const socialSignUp = async (method: string, tokenId: string) => {
  return await OneistoxAPI.post(`v1/client/signup/social`, { method, tokenId });
};

export const socialLogin = async (method: string, tokenId: string) => {
  return await OneistoxAPI.post(`v1/client/login/social`, { method, tokenId });
};

export const emailLogin = async (emailId: string, password: string) => {
  return OneistoxAPI.post(`v1/client/login/email`, { emailId, password });
};

export const emailVerification = async (
  encodedEmailId: string,
  token: string,
) => {
  return await OneistoxAPI.get(
    `v1/client/login/emailVerification/${encodedEmailId}?token=${token}`,
  );
};

export const passwordReset = async (emailId: string) => {
  return await OneistoxAPI.post(`v1/client/login/passwordReset`, { emailId });
};

export const passwordResetVerification = async (
  newPassword: string,
  verificationToken: string,
) => {
  return await OneistoxAPI.post('v1/client/login/passwordResetVerification', {
    newPassword,
    verificationToken,
  });
};

export const sendEmailVerification = async () => {
  return await OneistoxAPI.post(`v1/client/user/sendEmailVerification`);
};

export const passwordChange = async (
  oldPassword: string,
  newPassword: string,
) => {
  return await OneistoxAPI.post(`v1/client/user/passwordChange`, {
    oldPassword,
    newPassword,
  });
};

export const getUserData = async () => {
  return await OneistoxAPI.get(`v1/client/user/data`);
  // const res = await OneistoxAPI.get(`v1/client/user/data`)
  //   res.data.agentId = 15
  //   return res
};

export const getUserDataWithUserProfile = async (): Promise<{
  data: { userData: any; userProfile: any };
}> => {
  return await OneistoxAPI.get(`v1/client/user/data-profile`);
};

export const getUserProfile = async () => {
  return await OneistoxAPI.get(`v1/client/user/profile`);
};

export const updateUserData = async (phone: string) => {
  return await OneistoxAPI.put(`v1/client/user/data`, { phone });
};

export const updateUserProfile = async (userProfile) => {
  return await OneistoxAPI.put(`v1/client/user/profile`, { ...userProfile });
};

export const updateUserProfileV2 = async (userProfile) => {
  return await OneistoxAPI.put(`v2/client/user/profile`, { ...userProfile });
};

export const getProfileStatus = async () => {
  return await OneistoxAPI.put(`v1/client/user/profile/status`);
};

export const updateUserProject = async (project) => {
  return await OneistoxAPI.put(`v1/client/user/project`, { ...project });
};

export const uploadProfilePhoto = async (data) => {
  return await OneistoxAPI.put(`v1/client/user/profile/photo`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getCohort = async (cohortId: string) => {
  return await OneistoxAPI.get(`v1/client/lms/user/${cohortId}/cohort`);
};

export const getAllCohortUserData = async () => {
  return await OneistoxAPI.get(`v1/client/user/user-cohorts`);
};

export const getCohortUserData = async (cohortId: string) => {
  return await OneistoxAPI.get(
    `v1/client/lms/user/${cohortId}/cohort-user-data`,
  );
};

export const updateCohortUserDataMeta = async (
  cohortId: string,
  key: string,
  value,
) => {
  return await OneistoxAPI.put(
    `v1/client/lms/user/${cohortId}/cohort-user-data/meta`,
    { key, value },
  );
};

export const getCourseUnitUserCohortData = async (
  cohortId: string,
  unitId: string,
) => {
  return await OneistoxAPI.get(
    `v1/client/lms/product/${cohortId}/cohort-user-data/course-unit?unit-id=${unitId}`,
  );
};

export const getUserCohortlmsData = async (
  cohortId: string,
  courseId: string,
) => {
  return await OneistoxAPI.get(
    `v1/client/course-unit/${cohortId}/cohort-user-data/lms-data?course-id=${courseId}`,
  );
};

export const getCourseInfo = async (cohortId: string, courseId: string) => {
  return await OneistoxAPI.get(
    `v1/client/course-unit/${cohortId}/course-info?course-id=${courseId}&unit-id=C_ROOT`,
  );
};

export const getCourseUnit = async (
  cohortId: string,
  courseId: string,
  unitId: string,
) => {
  return await OneistoxAPI.get(
    `v1/client/course-unit/${cohortId}/?course-id=${courseId}&unit-id=${unitId}`,
  );
};

export const getModuleInfo = async (
  cohortId: string,
  courseId: string,
  unitId: string,
) => {
  return await OneistoxAPI.get(
    `v1/client/course-unit/${cohortId}/module-info?course-id=${courseId}&unit-id=${unitId}`,
  );
};

export const triggerCourseEvent = async (
  cohortId: string,
  courseId: string,
  courseModule: string,
  event: string,
) => {
  return OneistoxAPI.put(
    `v1/client/course-unit/${cohortId}/trigger-event?course-id=${courseId}&unit-id=${courseModule}&event-id=${event}`,
    null,
  );
};

export const getFormPage = async (
  formId: string,
  productId: string,
  questionId?: string,
) => {
  let url: string;
  if (questionId) {
    url = `v1/client/form/page/${formId}?question-id=${questionId}`;
  } else {
    url = `v1/client/form/page/${formId}`;
  }
  return OneistoxAPI.get(url, {
    headers: { 'x-product-id': productId },
  });
};

export const createFormResponse = async (
  formId: string,
  productId: string,
  meta,
) => {
  return OneistoxAPI.post(`v1/client/form/response/${formId}`, meta, {
    headers: { 'x-product-id': productId },
  });
};

export const getMetabaseToken = async (productId, payload) => {
  return OneistoxAPI.post(
    `v1/client/analytics/metabase/token`,
    { payload },
    {
      headers: { 'x-product-id': productId },
    },
  );
};

interface ResponseEntry {
  questionId: string;
  entries: {
    optionId?: string;
    asset?: { key: string; value: string };
  }[];
  eval?: 'CORRECT' | 'INCORRECT';
}

export const checkFormResponse = async (
  formId: string,
  productId: string,
  responseId: string,
  responseEntry: ResponseEntry,
) => {
  return OneistoxAPI.put(
    `v1/client/form/response/check/${formId}?response-id=${responseId}`,
    responseEntry,
    {
      headers: { 'x-product-id': productId },
    },
  );
};

// export const submitBulkFormResponse = async (
//   formId: string,
//   productId: string,
//   responseId: string,
//   responseEntries: ResponseEntry[],
// ) => {
//   return OneistoxAPI.put(
//     `v1/client/form/response/bulk/${formId}?response-id=${responseId}`,
//     responseEntries,
//     {
//       headers: { 'x-product-id': productId },
//     },
//   );
// };

export const submitDirectBulkFormResponse = async (
  formId: string,
  productId: string,
  responseEntries: ResponseEntry[],
  meta?: any,
  event?: string,
) => {
  return OneistoxAPI.put(
    `v1/client/form/response/direct-bulk/${formId}`,
    { responseEntries, meta, event },
    {
      headers: { 'x-product-id': productId },
    },
  );
};

export const getFormResponses = async (
  formId: string,
  productId: string,
  state: string,
) => {
  return OneistoxAPI.get(
    `v1/client/form/responses/form/${formId}?state=${state}`,
    {
      headers: { 'x-product-id': productId },
    },
  );
};

export const getFormReport = async (
  formId: string,
  productId: string,
  responseId: string,
) => {
  return OneistoxAPI.get(
    `v1/client/form/response/report/${formId}?response-id=${responseId}`,
    {
      headers: { 'x-product-id': productId },
    },
  );
};

export const createOrder = async (options) => {
  return OneistoxAPI.post(`v2/client/public/oms/order`, options);
};

export const cohortReview = async (productId: string, review) => {
  return OneistoxAPI.post(
    `v1/client/lms/user/${productId}/cohort-user-data/review`,
    review,
  );
};

export const getCohortMentors = async (cohortId: number) => {
  return OneistoxAPI.get(`v1/client/lms/product/${cohortId}/mentor/cohort`);
};

export const getMentorPageData = async () => {
  return OneistoxAPI.get(`v1/client/mentor/page`);
};

export const getMentorDetails = async (cohortId, mentors) => {
  return OneistoxAPI.put(
    `v1/client/lms/user/${cohortId}/agent-details`,
    mentors,
  );
};

export const getMentorLiveSessionsData = async (cohortId: number) => {
  return OneistoxAPI.get(`v1/client/lms/product/${cohortId}/liveSessions`);
};

export const getMentorLiveSessions = async () => {
  return OneistoxAPI.get(`v1/client/mentor/live-sessions`);
};

export const getMentorUserAggLiveSessionsData = async (cohortId: number) => {
  return OneistoxAPI.get(
    `v1/client/lms/product/${cohortId}/user/liveSessions/agg`,
  );
};

export const getMentorCohortAggLiveSessionsData = async (cohortId: number) => {
  return OneistoxAPI.get(
    `v1/client/lms/product/${cohortId}/cohort/liveSessions/agg`,
  );
};

export const competencyAndFeedbackClicked = async (
  cohortId: number,
  userResponse: any,
) => {
  return OneistoxAPI.put(
    `v1/client/lms/product/${cohortId}/cohort-user/state/toggle`,
    userResponse,
  );
};

export const getBlogsData = async () => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_ASSETS_2}/client/blogs/blogs.json`,
  );
};

export const getBlogData = async (url) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_ASSETS_2}/client/blogs/${url}.json`,
  );
};

export const getCourseMentors = async (cohortId) => {
  return OneistoxAPI.get(`v1/client/lms/product/${cohortId}/mentor/cohort`);
};

export const getCourseMentorsByAgent = async (cohortId) => {
  return OneistoxAPI.get(`v1/client/mentor/mentors/cohort/${cohortId}`);
};

export const getPublicSkillsList = async (cohortId) => {
  return OneistoxAPI.get(`v1/client/public/lms/skills?cohort-id=${cohortId}`);
};

export const getNotifications = async () => {
  return OneistoxAPI.get(`v1/client/in-app-notification/`);
};

export const markNotificationsRead = async (notificationId) => {
  return OneistoxAPI.put(
    `v1/client/in-app-notification/read?notification-id=${notificationId}`,
  );
};

export const markAllNotificationsRead = async () => {
  return OneistoxAPI.put(`v1/client/in-app-notification/all-read`);
};

export const deleteNotifications = async (notificationId) => {
  return OneistoxAPI.put(
    `v1/client/in-app-notification/delete?notification-id=${notificationId}`,
  );
};

export const getPublicProfile = async (userId) => {
  return OneistoxAPI.get(`v1/client/public/user/profile?user-id=${userId}`);
};

export const getUserCohortId = async (userId) => {
  return OneistoxAPI.get(
    `v1/client/public/user/profile/cohort?user-id=${userId}`,
  );
};

export const getUserRecommendations = async (userId) => {
  return OneistoxAPI.get(
    `v1/client/public/user/recommendations?user-id=${userId}`,
  );
};

export const addRecommendation = async (
  userId: number,
  recommendation: { id?: number; description: string },
) => {
  return OneistoxAPI.put(
    `v1/client/user/recommendation/${userId}`,
    recommendation,
  );
};

export const deleteRecommendation = async (userId) => {
  return OneistoxAPI.delete(`v1/client/user/recommendation/${userId}`);
};

export const uploadProfileProjectDocument = async (data) => {
  return await OneistoxAPI.put(
    `v1/client/user/profile/project-document`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const getMockBookingSlots = async (cohortId) => {
  return OneistoxAPI.get(
    `v1/client/lms/product/${cohortId}/cohort/meeting-slot/mock-interviews`,
  );
};

export const addMockBookingSlotUser = async (cohortId, slotId) => {
  return await OneistoxAPI.put(
    `v1/client/lms/product/${cohortId}/cohort/meeting-slot/mock-interviews/add?slot-id=${slotId}`,
  );
};

export const removeMockBookingSlotUser = async (cohortId, slotId) => {
  return await OneistoxAPI.put(
    `v1/client/lms/product/${cohortId}/cohort/meeting-slot/mock-interviews/remove?slot-id=${slotId}`,
  );
};

export const getProductPricing = async (
  productId,
  pricingStage,
  currency,
  voucherCode?,
  emailId?,
  billingContact?,
  billingAddress?,
) => {
  return OneistoxAPI.post(`v1/client/public/oms/pricing/${productId}`, {
    pricingStage,
    productId,
    currency,
    voucherCode,
    emailId,
    billingContact,
    billingAddress,
  });
};

export const isVoucherApplicable = async (
  productId,
  pricingStage,
  currency,
  voucherCode,
  emailId,
) => {
  return OneistoxAPI.post(
    `v1/client/public/oms/voucher/applicable/${productId}`,
    {
      pricingStage,
      productId,
      currency,
      voucherCode,
      emailId,
    },
  );
};

export const getPublicCohort = async (productId) => {
  return OneistoxAPI.get(`v1/client/public/lms/cohort/basic/${productId}`);
};

export const getOrders = async () => {
  return OneistoxAPI.get(`v1/client/user/orders`);
};

export const getProductOrder = async (productId) => {
  return OneistoxAPI.get(`v1/client/lms/user/${productId}/cohort/order`);
};

export const getPaymentPlan = async (orderId) => {
  return await OneistoxAPI.get(`v1/client/oms/${orderId}/payment-plan`);
};

export const getCourseFee = async (cohortId: string, currency: string) => {
  return await OneistoxAPI.get(
    `v1/internal/sales/oms/get-course-fee/${cohortId}/${currency}`,
  );
};

export const getOrder = async (orderId) => {
  return OneistoxAPI.get(`v1/client/oms/${orderId}/`);
};

export const createPostDownPaymentOrder = async (orderOptions) => {
  return OneistoxAPI.post(
    `v1/client/oms/${orderOptions.orderId}/order/preview/remaining`,
    orderOptions,
  );
};

export const createRazorpayPostDownPaymentOrder = async (orderOptions) => {
  return OneistoxAPI.post(
    `v1/client/oms/${orderOptions.notes.orderId}/order/razorpay/preview/remaining`,
    orderOptions,
  );
};

// export const generateInvoice = async (orderId, paymentId) => {
//   return OneistoxAPI.post(
//     `v1/client/oms/${orderId}/invoice/generate/${paymentId}`,
//   );
// };

export const getEmiForm = async (orderId, formId) => {
  return await OneistoxAPI.get(
    `v1/client/oms/${orderId}/order/emi/form/${formId}`,
  );
};

export const getEmiFormResponse = async (orderId, formId, responseId) => {
  return await OneistoxAPI.get(
    `v1/client/oms/${orderId}/order/emi/form-response/${formId}?response-id=${responseId}`,
  );
};

export const submitEmiForm = async (
  orderId,
  formId,
  responseEntries,
  responseId,
  meta?,
) => {
  return await OneistoxAPI.post(
    `v1/client/oms/${orderId}/order/emi/form/${formId}`,
    {
      responseEntries,
      meta,
      responseId,
    },
  );
};

export const uploadEmiDocument = async (orderId, data) => {
  return await OneistoxAPI.put(
    `v1/client/oms/${orderId}/order/emi/document`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const getEmiStatus = async (orderId) => {
  return await OneistoxAPI.get(`v1/client/oms/${orderId}/order/emi/status`);
};

export const rejectEmiApplication = async (orderId) => {
  return await OneistoxAPI.post(`v1/client/oms/${orderId}/order/emi/reject`);
};

export const submitEmiApplication = async (orderId) => {
  return await OneistoxAPI.post(`v1/client/oms/${orderId}/order/emi/submit`);
};

export const cancelUserCohort = async (productId) => {
  return await OneistoxAPI.post(
    `v1/client/user/cancel/user-cohorts/${productId}`,
  );
};

// live learning
export const getWeeklyLivesession = async (cohortId) => {
  return await OneistoxAPI.get(
    `v1/client/lms/user/${cohortId}/live-sessions/current-week`,
  );
};
export const getAllLiveSession = async (cohortId) => {
  return await OneistoxAPI.get(`v1/client/lms/user/${cohortId}/live-sessions`);
};

export const getAllLiveSessionV3 = async (cohortId) => {
  return await OneistoxAPI.get(`v3/client/lms/user/${cohortId}/live-sessions`);
};

export const getCourseModuleElectives = async (cohortId, moduleId) => {
  return await OneistoxAPI.get(
    `v1/client/lms/user/${cohortId}/course-module/electives?course-module-id=${moduleId}`,
  );
};

export const selectElective = async (cohortId: string, elective) => {
  return OneistoxAPI.post(
    `v1/client/lms/user/${cohortId}/cohort-user-data/select-elective`,
    elective,
  );
};

export const selectSpecialisation = async (
  cohortId: string,
  specialisation,
) => {
  return OneistoxAPI.post(
    `v1/client/lms/user/${cohortId}/cohort-user-data/select-elective-specialisation`,
    specialisation,
  );
};

export const getStats = async (cohortId) => {
  return await OneistoxAPI.get(
    `v1/client/lms/user/${cohortId}/learner-live-sessions/statistics`,
  );
};

export const getMonthlyEarnings = async () => {
  return await OneistoxAPI.get('v1/client/mentor/monthly-earning?months=6');
};

export const getEarningLogs = async () => {
  return await OneistoxAPI.get('v1/client/mentor/activity/earning-logs');
};

export const getReportsByUnitId = async (cohortId: string, unitId: string) => {
  return await OneistoxAPI.get(
    `v1/client/lms/product/${cohortId}/report?unit-id=${unitId}`,
  );
};

export const submitUnitIdReport = async (
  cohortId: string,
  unitId: string,
  submission,
) => {
  return await OneistoxAPI.post(
    `v1/client/lms/product/${cohortId}/report/submit?unit-id=${unitId}`,
    submission,
  );
};

export const submitUnitIdReportUpload = async (
  cohortId: string,
  unitId: string,
  data,
  fileName: string,
) => {
  const res = await OneistoxAPI.post(
    `v1/client/lms/product/${cohortId}/report/submit/upload/url?unit-id=${unitId}`,
    { fileName },
  );
  const { url, path, bucket } = res.data;
  await axios.put(url, data, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });
  return await OneistoxAPI.post(
    `v1/client/lms/product/${cohortId}/report/submit/upload?unit-id=${unitId}`,
    { path, bucket },
  );
};

export const getReportsV2 = async (cohortId: string) => {
  return await OneistoxAPI.get(`v1/client/lms/product/${cohortId}/reports`);
};

export const getAggSkillLogs = async (cohortId: string) => {
  return await OneistoxAPI.get(
    `v1/client/lms/product/${cohortId}/skill-logs/agg`,
  );
};

export const getPublicAggSkillLogsV3 = async (userId) => {
  return await OneistoxAPI.get(
    `v3/client/public/user/cohort/user-profile/skill-page?user-id=${userId}`,
  );
};

export const createReportAssessment = async (userId: string, report) => {
  return await OneistoxAPI.put(
    `v1/client/mentor/report/assessment?user-id=${userId}`,
    report,
  );
};

export const getMentorReportsV2 = async (cohortId: string) => {
  return await OneistoxAPI.get(`v1/client/mentor/reports/${cohortId}`);
};
export const createStripeOrder = async (options) => {
  return OneistoxAPI.post(`v1/client/public/oms/order/stripe`, options);
};

export const createStripePostDownPaymentOrder = async (orderOptions) => {
  return OneistoxAPI.post(
    `v1/client/oms/${orderOptions.notes.orderId}/order/stripe/preview/remaining`,
    orderOptions,
  );
};

export const getNodeTreeV2 = async (
  cohortId: string,
  projectId: string,
  nodeId?: string,
  config?,
) => {
  return await OneistoxAPI.post(
    `v2/client/${cohortId}/project/${projectId}/node/tree?node-id=${
      nodeId || 'ROOT'
    }`,
    config,
  );
};

export const getNodeTreesMapV2 = async (
  cohortId: string,
  projectId: string,
) => {
  return await OneistoxAPI.post(
    `v2/client/${cohortId}/project/${projectId}/node/trees/map`,
  );
};

export const getNodesStatesV2 = async (cohortId: string, projectId: string) => {
  return await OneistoxAPI.get(
    `v2/client/${cohortId}/project/${projectId}/nodes/states`,
  );
};

export const getTaskNodesStatesV2 = async (
  cohortId: string,
  projectId: string,
  nodeId: string,
) => {
  return await OneistoxAPI.post(
    `v2/client/${cohortId}/project/${projectId}/task/nodes/states?node-id=${nodeId}`,
  );
};

export const getMilestoneNodesStatesV2 = async (
  cohortId: string,
  projectId: string,
  nodeId: string,
) => {
  return await OneistoxAPI.post(
    `v2/client/${cohortId}/project/${projectId}/milestone/nodes/states?node-id=${nodeId}`,
  );
};

export const submitProjectTaskReportV2 = async (
  cohortId: string,
  projectId: string,
  nodeId: string,
  data,
) => {
  return await OneistoxAPI.post(
    `v2/client/${cohortId}/project/${projectId}/task/task/submit?node-id=${nodeId}`,
    data,
  );
};

export const submitProjectTaskReportUploadV2 = async (
  cohortId: string,
  projectId: string,
  nodeId: string,
  data,
  fileName,
) => {
  const res = await OneistoxAPI.post(
    `v2/client/${cohortId}/project/${projectId}/task/task/submit/upload/url?node-id=${nodeId}`,
    { fileName },
  );
  const { url, path, bucket } = res.data;
  await axios.put(url, data, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });
  return await OneistoxAPI.post(
    `v2/client/${cohortId}/project/${projectId}/task/task/submit/upload?node-id=${nodeId}`,
    { path, bucket },
  );
};

export const handleProjectQuizCompletedV2 = async (
  cohortId: string,
  projectId: string,
  nodeId: string,
  formId: string,
  responseId: string,
) => {
  return await OneistoxAPI.post(
    `v2/client/${cohortId}/project/${projectId}/task/quiz/completed?node-id=${nodeId}`,
    { formId, responseId },
  );
};

export const handleProjectContentSlidesCompleted = async (
  cohortId: string,
  projectId: string,
  nodeId: string,
) => {
  return await OneistoxAPI.post(
    `v2/client/${cohortId}/project/${projectId}/task/content-slides/completed?node-id=${nodeId}`,
  );
};

export const getProjectRootNodeStates = async (
  cohortId: string,
  projectId: string,
) => {
  return await OneistoxAPI.get(
    `v2/client/${cohortId}/project/${projectId}/root/states`,
  );
};

export const updateProjectRootNodeStates = async (
  cohortId: string,
  projectId: string,
  path: string,
  value,
) => {
  return await OneistoxAPI.post(
    `v2/client/${cohortId}/project/${projectId}/root/states`,
    { path, value },
  );
};

export const getProjectGoalV2 = async (cohortId: string, projectId: string) => {
  return OneistoxAPI.get(`v2/client/${cohortId}/project/${projectId}/role`);
};

export const setProjectGoalV2 = async (
  cohortId: string,
  projectId: string,
  role: string,
) => {
  return OneistoxAPI.put(
    `v2/client/${cohortId}/project/${projectId}/role?role-id=${role}`,
  );
};

export const getTaskReportsV2 = async (cohortId, unitId) => {
  return OneistoxAPI.get(
    `v1/client/lms/product/${cohortId}/reports?unit-id=${unitId}`,
  );
};

export const getGroupsStates = async (cohortId: string, projectId: string) => {
  return OneistoxAPI.get(
    `v2/client/${cohortId}/project/${projectId}/groups/states`,
  );
};

export const getUserGroupStates = async (
  cohortId: string,
  projectId: string,
) => {
  return OneistoxAPI.get(
    `v2/client/${cohortId}/project/${projectId}/group/states/user`,
  );
};

export const getUserGroupAndGroupsStates = async (
  cohortId: string,
  projectId: string,
) => {
  return OneistoxAPI.get(
    `v2/client/${cohortId}/project/${projectId}/group/states/user-groups`,
  );
};

export const getSkillRecos = async (cohortId: string, projectId: string) => {
  return OneistoxAPI.get(
    `v2/client/${cohortId}/project/${projectId}/skills-recos`,
  );
};

export const getAgentForm = async (formId) => {
  return await OneistoxAPI.get(`v1/client/agent/form/${formId}`);
};

export const getMentorForm = async (formId, cohortId) => {
  return await OneistoxAPI.get(
    `v1/client/mentor/form/${formId}?cohort-id=${cohortId}`,
  );
};

export const bulkDirectRegisterAgentForm = async (
  formId,
  responseEntries,
  meta?,
) => {
  return await OneistoxAPI.put(
    `v1/client/agent/response/direct-bulk/${formId}`,
    {
      responseEntries,
      meta,
    },
  );
};

export const createMentorQueriesEarningLog = async (
  responseId,
  formId,
  cohortId,
) => {
  return await OneistoxAPI.post(`v1/client/mentor/queries/earning`, {
    responseId,
    formId,
    cohortId,
  });
};

// Hiring Dashboard  API'S
export const createHiringPartner = async (
  firstName,
  lastName,
  designation,
  companyName,
  employeeStrength,
) => {
  return await OneistoxAPI.post(
    `v1/client/hiring/hiring-partner/hiring-partner`,
    {
      firstName,
      lastName,
      designation,
      companyName,
      employeeStrength,
    },
  );
};
export const createHiringPartnerJob = async (
  openings,
  positionId,
  location,
  meta,
  expectedDateToClose,
) => {
  return await OneistoxAPI.post(`v1/client/hiring/hiring-partner/job`, {
    openings,
    positionId,
    location,
    meta,
    expectedDateToClose,
  });
};

export const getHiringPartnerData = async () => {
  return await OneistoxAPI.get(`v1/client/hiring/hiring-partner/extended`);
};

export const getHiringMetricsData = async () => {
  return await OneistoxAPI.get(`v1/client/hiring/hiring-partner/jobs/view`);
};

export const getHiringPartnerInterviewSlots = async (userId: number) => {
  return await OneistoxAPI.get(
    `v1/client/hiring/hiring-partner/job/interview-slots/${userId}`,
  );
};

export const getUserUpcomingInterviews = async () => {
  return await OneistoxAPI.get(`v1/client/hiring/user/upcoming-interviews`);
};

export const getHPUpcomingInterviews = async () => {
  return await OneistoxAPI.get(
    `v1/client/hiring/hiring-partner/upcoming-interviews`,
  );
};

export const markClosedJob = async (data) => {
  return await OneistoxAPI.put(`v1/client/hiring/hiring-partner/job`, {
    ...data,
  });
};

export const getUserJobsData = async () => {
  return await OneistoxAPI.get(`v1/client/hiring/user/jobs-data`);
};

export const bookInterviewSlots = async (bookSlotsData) => {
  return await OneistoxAPI.post(
    `v1/client/hiring/hiring-partner/job/book-interview-slots`,
    bookSlotsData,
  );
};

export const rejectCandidate = async (userJobId: string, reasons: string[]) => {
  return await OneistoxAPI.post(
    `v1/client/hiring/hiring-partner/job/applicant-rejected`,
    { userJobId, reasons },
  );
};

export const getUserSlots = async (userJobId: string) => {
  return await OneistoxAPI.get(
    `v1/client/hiring/user/interview-slots/${userJobId}`,
  );
};

export const confirmSlot = async (userJobId: string, slotId: string) => {
  return await OneistoxAPI.post(`v1/client/hiring/user/book-interview-slots`, {
    userJobId,
    userSlotIds: [slotId],
  });
};

export const confirmInterview = async (userJobId: string, slotId: string) => {
  return await OneistoxAPI.post(`v1/client/hiring/user/confirm-interview`, {
    userJobId,
    slotId,
  });
};

export const acceptApplication = async (userJobId: string) => {
  return await OneistoxAPI.post(
    `v1/client/hiring/hiring-partner/job/accept-application`,
    { userJobId },
  );
};

export const sendUserFeedback = async (feedback) => {
  return await OneistoxAPI.post(
    `v1/client/hiring/hiring-partner/job/interview-closed`,
    feedback,
  );
};

export const finaliseCandidate = async (
  userJobId,
  offerLetterLink,
  finalCTC,
) => {
  return await OneistoxAPI.post(
    `v1/client/hiring/hiring-partner/job/applicant-accepted`,
    { userJobId, offerLetterLink, finalCTC },
  );
};

export const uploadOfferLetter = async (userJobId: string, data) => {
  return await OneistoxAPI.put(
    `v1/client/hiring/hiring-partner/job/applicant-accepted/offer-letter/${userJobId}`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const uploadJD = async (jobId: number, data) => {
  return await OneistoxAPI.put(
    `v1/client/hiring/hiring-partner/job/jd/${jobId}`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const userJobAccepted = async (userJobId: string) => {
  return await OneistoxAPI.post(`v1/client/hiring/user/user-accepted`, {
    userJobId,
  });
};

export const getPublicMeeting = async (token: string) => {
  return await OneistoxAPI.get(`v1/public/meeting/${token}`);
};
export const userRejectOffer = async (data) => {
  return await OneistoxAPI.post(`v1/client/hiring/user/user-rejected`, {
    userJobId: data.userJobId,
    reasons: data.reasons,
  });
};
export const userRejectInterview = async (data) => {
  return await OneistoxAPI.post(
    `v1/client/hiring/user/user-rejected-interview`,
    {
      userJobId: data.userJobId,
      reasons: data.reasons,
    },
  );
};
export const companyFeedbackQuery = async (data) => {
  return await OneistoxAPI.post(`v1/client/hiring/hiring-partner/contact-us`, {
    companyId: data.companyId,
    title: data.title,
    message: data.message,
  });
};

export const userFeedbackQuery = async (data) => {
  return await OneistoxAPI.post(`v1/client/hiring/user/contact-us`, {
    userId: data.userId,
    title: data.title,
    message: data.message,
  });
};
// End Hiring Dashboard  API'S

// Placement onboarding

export const updatePlacementOnboarding = async (data) => {
  return await OneistoxAPI.put(`v1/client/hiring/user/placement-details`, {
    ...data,
  });
};

export const getLeaderBoard = async (cohortId) => {
  return await OneistoxAPI.get(`v1/client/lms/user/${cohortId}/leaderboard`);
};

export const createInstallmentPaymentPreview = async (
  orderId,
  paymentPlanId: string,
  client,
) => {
  return OneistoxAPI.post(
    `v1/client/oms/${orderId}/order/payment-plan-review`,
    { paymentPlanId, client },
  );
};

export const getEmiFormResponseV2 = async (
  orderId,
  formId,
  responseId,
  planId,
) => {
  return await OneistoxAPI.post(
    `v1/client/oms/${orderId}/order/plan/emi/form-response/${formId}?response-id=${responseId}`,
    { planId },
  );
};

export const submitEmiFormV2 = async (
  orderId,
  formId,
  responseEntries,
  responseId,
  planId,
  meta?,
) => {
  return await OneistoxAPI.post(
    `v1/client/oms/${orderId}/order/plan/emi/form/${formId}`,
    {
      responseEntries,
      meta,
      planId,
      responseId,
    },
  );
};

export const getEmiStatusV2 = async (orderId, planId) => {
  return await OneistoxAPI.post(
    `v1/client/oms/${orderId}/order/plan/emi/status`,
    { planId },
  );
};

export const submitEmiApplicationV2 = async (orderId, planId) => {
  return await OneistoxAPI.post(
    `v1/client/oms/${orderId}/order/plan/emi/submit`,
    { planId },
  );
};

export const getBankTransferDetails = async (planId) => {
  return await OneistoxAPI.post(
    `v1/client/public/oms/create-smart-collect-order/${planId}`,
  );
};

export const getJobs = async () => {
  return await OneistoxAPI.get(
    'v1/client/hiring/user/hiring-partner-jobs-data',
  );
};
export const getUserProfileData = async () => {
  return await OneistoxAPI.get('v1/client/hiring/user/user-profile-data');
};

export const createUserJob = async (userJob) => {
  return OneistoxAPI.post(`v1/client/hiring/user/user-job`, userJob);
};

export const isUserPlacementEligible = async () => {
  return OneistoxAPI.get(`v1/client/hiring/user/placements/check-eligibility`);
};
