import { createSlice } from '@reduxjs/toolkit';

export const learnModeSlice = createSlice({
  name: 'learnMode',
  initialState: {
    counter: 0,
    sessionData: null,
    projectGoal: null,
    currentUnitId: 'M1',
    currentNodeId: null,
    cohort: null,
    cohortUserData: null,
    userSkillList: null,
    userState: null,
  },
  reducers: {
    updateSessionData: (state, action) => {
      const { payload } = action;
      state.sessionData = payload.sessionData;
    },
    updateCurrUnitId: (state, action) => {
      const { payload } = action;
      if (payload.reload) {
        state.currentUnitId = payload.currentUnitId;
        state.counter = state.counter + 1;
      } else {
        state.currentUnitId = payload.currentUnitId;
      }
    },
    updateCurrNodeId: (state, action) => {
      const { payload } = action;
      state.currentNodeId = payload.currentNodeId;
    },
    updateCohort: (state, action) => {
      const { payload } = action;
      state.cohort = payload.cohort;
    },
    updateCohortUserData: (state, action) => {
      const { payload } = action;
      state.cohortUserData = payload.cohortUserData;
    },
    updateUserSkillList: (state, action) => {
      const { payload } = action;
      state.userSkillList = payload.userSkillList;
    },
    updateUserState: (state, action) => {
      const { payload } = action;
      state.userState = payload.userState;
    },
    updateProjectGoal: (state, action) => {
      const { payload } = action;
      state.projectGoal = payload.projectGoal;
    },
  },
});
