import Router from 'next/router';
import { createSlice } from '@reduxjs/toolkit';
import { removeAuthToken, setAuthToken } from '../client/OneistoxApiClient';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userId: '',
    userData: {} as any,
    userProfile: {} as any,
    isAuthenticated: null,
    loading: false,
    error: '',
    emailLoginMethod: null,
    name: '',
    mobile: '',
  },
  reducers: {
    signUpStart: (state, action) => {
      const { payload } = action;
      state.loading = true;
    },
    signUpSuccess: (state, action) => {
      const { payload } = action;
      setAuthToken(payload.token);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        gtm: { user_id: payload.userId },
        user_id: payload.userId,
      });

      state.userId = payload.userId;
      state.emailLoginMethod = payload.emailLoginMethod;
      state.isAuthenticated = payload.isAuthenticated;
    },
    signUpFail: (state, action) => {
      const { payload } = action;
      removeAuthToken();

      state.isAuthenticated = false;
      state.loading = false;
    },
    authenticate: (state, action) => {
      const { payload } = action;

      state.emailLoginMethod = payload.userData.hashedPassword === '1';
      state.userId = payload.userData.userId;
      state.userData = { ...state.userData, ...payload.userData };
      state.userProfile = { ...state.userProfile, ...payload.userProfile };
      state.isAuthenticated = true;
      state.loading = false;
    },
    loginStart: (state, action) => {
      const { payload } = action;

      state.loading = true;
    },
    loginSuccess: (state, action) => {
      const { payload } = action;

      setAuthToken(payload.token);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        gtm: { user_id: payload.userId },
        user_id: payload.userId,
      });

      state.userId = payload.userId;
      state.emailLoginMethod = payload.emailLoginMethod;
      state.isAuthenticated = payload.isAuthenticated;
      state.loading = false;
    },
    logout: (state, action) => {
      const { payload } = action;
      removeAuthToken();
      payload?.redirect && Router.push('/');

      state.userId = '';
      state.userData = {};
      state.userProfile = {};
      state.emailLoginMethod = null;
      state.isAuthenticated = false;
      state.loading = false;
    },
    stepSave: (state, action) => {
      const { payload } = action;

      state.name = payload.name;
      state.mobile = payload.mobile;
    },
  },
});
