import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import Script from 'next/script';
import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from '@mui/material/styles';
import { useRouter } from 'next/router';
import 'react-phone-input-2/lib/style.css';

import { onRouteChange } from '../analytics/analytics';
import theme from '../components/Layouts/GlobalTheme';
import store from '../store';

import '../public/static/css/style.css';

const MyApp = (props) => {
  const { Component, pageProps } = props;
  const router = useRouter();
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' &&
      router.events
    ) {
      router.events.on('routeChangeComplete', (url) => {
        onRouteChange(
          window.location.href,
          router.asPath.split('?')?.[0],
          window.document.referrer,
          router.asPath.split('?')?.[1],
        );
      });
    }

    if (window && !sessionStorage.getItem('initial_landing_page')) {
      sessionStorage.setItem('initial_landing_page', window?.location?.href);
    }
  }, [router.events]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' &&
      router.isReady
    ) {
      onRouteChange(
        window.location.href,
        router.asPath.split('?')?.[0],
        window.document.referrer,
        router.asPath.split('?')?.[1],
      );
    }
  }, [router.isReady]);

  if (
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT !== 'production' &&
    !isLoaded
  ) {
    return <></>;
  }

  // <Component/> returns the component it self
  // pageProps returns the props you use to in that component. It can be any data
  // router.route returns the route your component lives on. So in our case it will be '/' or '/products/[id]'
  // exitBeforeEnter: AnimatePresence will only render one component at a time. The exiting component will finished its exit animation before the entering component is rendered
  return (
    <Sentry.ErrorBoundary showDialog>
      <Provider store={store}>
        <AnimatePresence exitBeforeEnter>
          <ThemeProvider theme={theme}>
            {/* university segment start */}
            {process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' && (
              <Script
                id="segement-script-loader"
                dangerouslySetInnerHTML={{
                  __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://1dyb57pgxg.execute-api.eu-west-3.amazonaws.com";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
              analytics.load("${process.env.NEXT_PUBLIC_UNIVERSITY_SEGMENT_ID}");
            }}();`,
                }}
              />
            )}
            {/* university segment end */}
            {/* Start of HubSpot Embed Code */}
            {process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' && (
              <Script
                type="text/javascript"
                id="hs-script-loader"
                src="//js.hs-scripts.com/19948058.js"
              />
            )}
            {/* End of HubSpot Embed Code */}

            {/* Start of Clarity Code */}
            {process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' && (
              <Script
                id="clarity-script-loader"
                dangerouslySetInnerHTML={{
                  __html: `(function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "ajfi6gtme6");`,
                }}
              />
            )}
            {/* End of Clarity Embed Code */}

            {/*<!-- Facebook Pixel Code -->*/}
            {/*{process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' && (
              <Script
                id="fb-pixel-script-loader"
                dangerouslySetInnerHTML={{
                  __html: `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '2175270895894311');
            fbq('track', 'PageView');`,
                }}
                strategy="lazyOnload"
              />
            )}*/}
            {/*<!-- Start GA Code -->*/}

            {process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' && (
              <Script
                id="gtag-script-loader"
                dangerouslySetInnerHTML={{
                  __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-698995117', { page_path: window.location.pathname });
            `,
                }}
              />
            )}

            {process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' && (
              <Script
                id="ga-script-loader"
                src="https://www.googletagmanager.com/gtag/js?id=AW-698995117"
              />
            )}
            {process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' && (
              <Script
                id="gtag-script-loader"
                dangerouslySetInnerHTML={{
                  __html: ` window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                     gtag('config', 'G-Y4KMVMM27P');`,
                }}
              />
            )}
            {process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' && (
              <Script
                id="ga-script-loader"
                src="https://www.googletagmanager.com/gtag/js?id=G-Y4KMVMM27P"
              />
            )}

            {process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' && (
              <Script
                id="heap-script-loader"
                dangerouslySetInnerHTML={{
                  __html: `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
                heap.load("${process.env.NEXT_PUBLIC_HEAP_KEY}");
          `,
                }}
              />
            )}

            {process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' && (
              <noscript>
                <iframe
                  title="googler tag manager"
                  src={`https://www.googletagmanager.com/ns.html?id=GTM-K5J6PVH`}
                  height="0"
                  width="0"
                  style={{ display: 'none', visibility: 'hidden' }}
                />
              </noscript>
            )}

            {/*<!-- End Facebook Pixel Code -->*/}
            <Component {...pageProps} key={router.route} />
          </ThemeProvider>
        </AnimatePresence>
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

export default MyApp;
