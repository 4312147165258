import { createSlice } from '@reduxjs/toolkit';

export const dashboardWorkshopsSlice = createSlice({
  name: 'dashboardWorkshops',
  initialState: {
    userCohorts: [],
    loaded: false,
  },
  reducers: {
    getDashboardWorkshops: (state, action) => {
      const { payload } = action;

      state.userCohorts = [...payload.userCohorts];
      state.loaded = true;
    },
  },
});
