import { createSlice } from '@reduxjs/toolkit';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    current: '',
    page: { loaded: true },
    error: null,
    message: null,
  },
  reducers: {
    dashboardPageLoadStart: (state, action) => {
      const { payload } = action;

      state.page = { loaded: false };
      state.error = null;
      state.message = null;
    },
    dashboardPageLoadRemove: (state, action) => {
      const { payload } = action;

      state.page = { loaded: true };
      state.error = { ...payload?.error };
      state.message = payload?.message;
    },
  },
});
